import { QRCode } from 'antd';
import { AxiosResponse } from 'axios';
import Text from 'components/Text';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { formatPhone } from 'utils/formatPhone';
import shopGiftCardSelectors from '../GiftCardByShop/services/selectors';
import banner from './img/banner.jpg';
import giftCardApis from './services/apis';
import { IGiftCardDetailResData, IPaymentGiftCardInfo } from './services/types/giftCard';

const GiftCardDetailSalon = ({ }) => {
  const { id = '' } = useParams();
  const shopDetail = shopGiftCardSelectors.getShopData();
  const { companyName, phone = '', address, city, state, zip: zipCode } = shopDetail || {};
  const setLoadingPage = useSetLoadingPage();
  const [result, setData] = useState<IPaymentGiftCardInfo | null>(null);
  const date = useMemo(() => moment(result?.date), [result]);

  const fetchData = async () => {
    setLoadingPage(true);
    try {
      const res: AxiosResponse<{ payload: IGiftCardDetailResData }> = await giftCardApis.getDetailGiftCard(id);
      const _data = res.data.payload;
      if (res.data.payload) {
        const data: IPaymentGiftCardInfo = {
          amount: _data.amount,
          date: _data.createDate,
          authCode: '',
          customerName: _data.customerNameTo,
          giftCardCode: _data.giftCardCode,
          paymentMethod: 'Credit Card',
          transNum: '',
        };
        setData(data);
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Banner className='banner'>
        <div className="box">
          <Text className='title'>{companyName}</Text>
          <Text className='info'>{[address, city, state, zipCode].filter(o => !!o).join(', ')}</Text>
          <Text className='info'>{formatPhone(phone)}</Text>
        </div>
      </Banner>
      <Page>
        <div className='box' style={{
          'borderRadius': '12px',
          'background': '#FFF',
          boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 4px',
          padding: '24px',
        }}>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Date:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{date.format('ddd, MM-DD-YYYY')}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Time:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{date.format('HH:mm A')}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Payment Method:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{result?.paymentMethod || 'Credit Card'}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Name On Card:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{result?.customerName || ''}</span>
            </div>
            <div style={{
              width: '100%',
              height: 1,
              backgroundImage: 'linear-gradient(to right, #DADADA 50%, rgba(255,256,255,0) 0%)',
              backgroundPosition: 'bottom',
              backgroundSize: '16px 1px',
              backgroundRepeat: 'repeat-x',
              margin: '2px 0',
            }} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span
                style={{
                  color: '#566A7F',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: 'normal',
                }}
              >Amount:</span>
              <span
                style={{
                  color: '#1D2129',
                  textAlign: 'center',
                  fontSize: '18px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >{formatCurrency(parseFloat(String(result?.amount ?? '')))}</span>
            </div>
          </div>
          <div style={{ height: 1, width: '100%', background: '#DADADA', margin: '16px 0' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
              <QRCode size={300} value={result?.giftCardCode?.toString() || ''} />
              <div style={{ fontSize: 24 }}>
                {result?.giftCardCode}
              </div>
            </div>
          </div>
        </div>
      </Page >
    </>
  );
};

export default GiftCardDetailSalon;

const Page = styled.div`
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  .box {
    width: 100%;
    max-width: 20rem;
  }
  @media only screen and (max-width: 600px) {
    .box {
      width: 100%;
      max-width: unset;
    } 
  }
`;

const Banner = styled.div`
display: flex;
justify-content: center;
align-items: center;
position: relative;
padding: 1.125rem 0;
background-repeat: no-repeat;
background-size: cover;

background-image: url(${banner});
background-repeat: no-repeat;
background-position: center;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(15px); 
    padding: 1rem 0;
    max-width: 375px;
    img {
      width: 80%;
      border-radius: 8px;
    }
  }

  .title {
    color: #FFF;
    text-align: center;
    font-family: Poller One;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .info {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from './NotFound';
import BuyGiftCard from './shopping/GiftCard/BuyGiftCard';
import BuyGiftCardSuccess from './shopping/GiftCard/BuyGiftCardSuccess';
import DejavooBuyGiftCardPage from './shopping/GiftCard/GiftCardDejavoo/BuyGiftCard';
import DejavooBuyGiftCardFail from './shopping/GiftCard/GiftCardDejavoo/BuyGiftCardFail';
import DejavooBuyGiftCardSuccess from './shopping/GiftCard/GiftCardDejavoo/BuyGiftCardSuccess';
import DejavooGiftCardListPage from './shopping/GiftCard/GiftCardDejavoo/GiftCardList';
import GiftCardDetail from './shopping/GiftCard/GiftCardDetail';
import GiftCardListPage from './shopping/GiftCard/GiftCardList';
import PaymentInfo from './shopping/GiftCard/PaymentInfo';
import ShopBuyGiftCardPage from './shopping/GiftCardByShop/BuyGiftCard';
import ShopBuyGiftCardSuccess from './shopping/GiftCardByShop/BuyGiftCardSuccess';
import ShopGiftCardListPage from './shopping/GiftCardByShop/GiftCardList';
import ShopGiftCardWrapper from './shopping/GiftCardByShop/ShopGiftCardWrapper';
import ShopBuyGiftCardFail from './shopping/GiftCardByShop/BuyGiftCardFail';
import GiftCardDetailSalon from './shopping/GiftCard/GiftCardDetailSalon';

const RootScreens = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path='' element={<GiftCardListPage />} />
        <Route path='/:id' >
          <Route path='' element={<BuyGiftCard />} />
          <Route path='pay' element={<PaymentInfo />} />
          <Route path='pay/completed' element={<BuyGiftCardSuccess />} />
        </Route>
        <Route path='v2'>
          <Route path='' element={<DejavooGiftCardListPage />} />
          <Route path=':id' >
            <Route path='' element={<DejavooBuyGiftCardPage />} />
            <Route path='fail' element={<DejavooBuyGiftCardFail />} />
            <Route path='success' element={<DejavooBuyGiftCardSuccess />} />
          </Route>
        </Route>
        <Route path='store/:shop_id' element={<ShopGiftCardWrapper />}>
          <Route path='' element={<ShopGiftCardListPage />} />
          <Route path='buy/:id' >
            <Route path='' element={<ShopBuyGiftCardPage />} />
            <Route path='fail' element={<ShopBuyGiftCardFail />} />
            <Route path='success' element={<ShopBuyGiftCardSuccess />} />
          </Route>
          <Route path={'gift-card/:id'} element={<GiftCardDetailSalon />} />
        </Route>
        <Route path={'gift-card/:id'} element={<GiftCardDetail />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </React.Fragment >
  );
};

export default RootScreens;
